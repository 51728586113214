<template>
  <!-- 健康咨询订单详情 -->
  <div
    class="formdata"
    style="padding: 20px;"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
    >
      <h4 class="title">
        订单基本信息
      </h4>
      <el-row>
        <el-col :span="8">
          <el-form-item label="业务订单号 :">
            <span>{{ form.orderNo }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="咨询时间 :">
            <span>{{ form.resource.consultationTime }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="问诊方式 :">
            <span>{{ consultationTypeDic[$route.query.type] }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.completeTime">
        <el-col :span="8">
          <el-form-item label="结束时间 :">
            <span>{{ form.completeTime }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务时长 :">
            <span>{{ queryDuration(form) }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="抢单时间 :">
            <span>{{ form.createTime }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="订单业务状态 :">
            <span>{{ orderStatusList[form.consultationStatus] }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="订单价格 :">
            <span>{{ form.resource.doctorSettlementFee }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付价格 :">
            <span>{{ form.resource.payFee }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="title">
        医生基本信息
      </h4>
      <el-row>
        <el-col :span="8">
          <el-form-item label="医生团队名称 :">
            <span>{{ form.teamName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="科室名称 :">
            <span>{{ form.deptName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="医生姓名 :">
            <span>{{ form.doctorName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="年龄 :">
            <span>{{ getDoctorAge(form.idCard) }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式 :">
            <!-- <span>{{ form.mobile }}</span> -->
            <span v-if="!allPhoneD">{{ form.mobile|hidePhone }}</span>
            <span v-else>{{ form.mobile }}</span>
            <i
              class="el-icon-view"
              @click="isShow(0)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="医生职称 :">
            <span>{{ form.professionalTitleName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="title">
        患者基本信息
      </h4>
      <el-row>
        <el-col :span="8">
          <el-form-item label="患者名称 :">
            <span>{{ form.resource.patientName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式 :">
            <!-- <span>{{ form.resource.patientMobile }}</span> -->
            <span v-if="!allPhoneP">{{ form.resource.patientMobile|hidePhone }}</span>
            <span v-else>{{ form.resource.patientMobile }}</span>
            <i
              class="el-icon-view"
              @click="isShow(1)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别 :">
            <span>{{ form.resource.patientSex==1?'男':'女' }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="年龄 :">
            <span>{{ form.resource.patientAge }}</span>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="主诉 :">
            <span>{{ form.serviceName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="现病史 :">
            <span>{{ form.specName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="个人史 :">
            <span>{{ form.a }}</span>
          </el-form-item>
        </el-col> -->
      </el-row>
      <h4 class="title">
        咨询内容
      </h4>
      <div class="health-content">
        {{ form.resource.consultationContent }}
      </div>
      <div
        v-if="form.resource.consultationImageUrl"
        class="imgstyle"
      >
        <el-image
          v-for="(item,index) in form.resource.consultationImageUrl.split(',')"
          :key="index"
          style="width: 150px; height: 150px; margin-right: 10px;"
          :src="item"
          fit="scale-down"
          :preview-src-list="form.resource.consultationImageUrl.split(',')"
          @click="solveRefresh()"
        />
      </div>
      <!-- <el-form-item
        v-if="form.resource.consultationContent"
        :label="form.resource.consultationContent+':'"
      > -->
      <!-- <div
          v-if="form.resource.consultationImageUrl"
          class="imgstyle"
        >
          <el-image
            v-for="(item,index) in form.resource.consultationImageUrl.split(',')"
            :key="index"
            style="width: 150px; height: 150px; margin-right: 10px;"
            :src="item"
            fit="scale-down"
            :preview-src-list="form.resource.consultationImageUrl.split(',')"
            @click="solveRefresh()"
          />
        </div>
      </el-form-item> -->
      <template v-if="form.consultationStatus==='CANCELED'">
        <h4 class="title">
          取消原因
        </h4>
        <el-form-item>
          <span>{{ form.cancelReason }}</span>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button
          type="primary"
          @click="$router.go(-1)"
        >
          返回
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

  import { mapActions } from 'vuex';
  import {orderStatusList} from '@/utils/component/publicList';
  import { getDuration } from '@/utils/index';
  export default {
    name: 'Details',
    data() {
      this.transferPay = {
        'WEPAY': '微信',
        'ALIPAY': '支付宝',
        'OTHER': '其他'
      };
      return {
        allPhoneD:false, // 是否展示完全的电话号码
        allPhoneP:false, // 是否展示完全的电话号码
        consultationTypeDic:JSON.parse(this.$route.query.consultationTypeDic), // 问诊类型字典
        orderStatusList:orderStatusList, // 订单业务状态
        form: {
          orderNo:'',
          teamName:'',
          createTime:'',
          consultationStatus:'',
          deptName:'',
          doctorName:'',
          occupationName:'',
          idCard:'',
          cancelReason:'',
          mobile:'',
          resource:{
            consultationTime:'',
            patientName:'',
            patientMobile:'',
            patientSex:'',
            consultationContent:'',
            consultationImageUrl:''
          }
        },
        rules: {}
      };
    },
    computed: {
    },
    watch: {},
    mounted() {
      this.getOrderDetail();
    },
    methods: {
      ...mapActions('orderManage', ['queryDetail']),
      // 电话号码显示隐藏切换
      isShow(index) {
        if(index === 0) {
          this.allPhoneD = !this.allPhoneD;
        }else{
          this.allPhoneP = !this.allPhoneP;
        }

      },
      // 获取医生年龄
      getDoctorAge(UUserCard) {
        // 获取年龄
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
        if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) === month && UUserCard.substring(12, 14) <= day)
        {
          age++;
        }
        return age;

      },
      // 订单详情
      getOrderDetail() {
        const param = {
          orderNo: this.$route.query.orderDetailNum
        };
        this.queryDetail(param).then(res => {
          this.form = res.data;
        });
      },
      // el-image点击图片页面卡顿问题
      solveRefresh() {
        document.body.style = '';
      },
      // 获取时长
      queryDuration(row) {
        const { createTime, completeTime } = row;
        return getDuration(createTime, completeTime);
      },
    }
  };
</script>

<style lang="scss" >
// 预约时间的可预约样式
</style>
<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle { margin-right: 10px; }

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.orderstatus {
  padding: 10px 5px;
  margin-left: 40px;
  border: 1px solid black;
}

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}

.health-content{
  margin:20px 50px;
}

.imgstyle {
  display: flex;
  margin:20px 50px;
  //  justify-content:space-between;
}
</style>
